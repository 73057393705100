import React, { useState, useEffect } from 'react';

const NewGamesAnnouncementPopup = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [rotateX, setRotateX] = useState(0);
  const [rotateY, setRotateY] = useState(0);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes('pbingo') || url.includes('phoenix') || url.includes('local')) {
      setIsOpen(true);
    }
  }, []);

  const handleMouseMove = (e) => {
    const card = e.currentTarget;
    const rect = card.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    const rotateY = ((x - centerX) / centerX) * 10;
    const rotateX = ((centerY - y) / centerY) * 10;
    
    setRotateX(rotateX);
    setRotateY(rotateY);
  };

  const handleMouseLeave = () => {
    setRotateX(0);
    setRotateY(0);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-opacity-40 flex justify-center items-center z-50 perspective-1000">
      <div
        className="relative bg-gradient-to-br from-red-800 to-blue-900 p-8 rounded-xl max-w-3xl w-full mx-4 text-center shadow-2xl transform-gpu transition-transform duration-200 ease-out"
        style={{
          transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`,
          boxShadow: '0 25px 50px -12px rgba(0, 0, 0, 0.5), 0 0 30px 5px rgba(139, 92, 246, 0.3)',
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      >
        <div className="absolute inset-0 bg-white bg-opacity-10 rounded-xl backdrop-blur-sm -z-10"></div>
        <div className="absolute inset-0 rounded-xl border-4 border-red-400 glow-red"></div>
        
        <h1 className="text-blue-200 text-4xl font-bold mb-8 transform hover:scale-105 transition-transform duration-300 text-shadow-glow">
          በህዳር የፊንክስ ጌምስ ታላቁ ሳፕራይዝ!
        </h1>
        <h2 className="text-yellow-300 text-6xl font-bold mb-6 transform hover:scale-105 transition-transform duration-300 text-shadow-glow">
          እንኳን ደስ አለዎት!
        </h2>
        
        {/* <p className="text-white text-2xl mb-6 animate-float">
          በዚህ ሳምንት የሚወጡ አዳዲስ ጨዋታዎች
        </p> */}
        
        <p className="text-red-200 text-3xl font-semibold mb-4 animate-pulse">
          በዚህ ሳምንት የሚለቀቁ ጨዋታዎች
        </p>
        
        <ul className="text-white text-2xl list-none p-0 mb-8 space-y-4 max-w-lg mx-auto">
          {[
            'አባዥ Bingo (ዛሬ አርብ ጥቅምት 29 ተለቀቀ)!',
            'ዙር Bingo',
            'ጊዜ Bingo'
          ].map((game, index) => (
            <li
              key={index}
              className={`relative overflow-hidden rounded-xl py-3 px-6 transform hover:scale-105 transition-all duration-300 shadow-xl ${
                index === 0 
                  ? 'bg-gradient-to-r from-emerald-600 to-teal-600 animate-pulse'
                  : 'bg-gradient-to-r from-blue-700 to-purple-700'
              }`}
            >
              <div className="absolute inset-0 bg-white opacity-0 hover:opacity-10 transition-opacity duration-300"></div>
              {index === 0 ? (
                <span className="font-bold">{game}</span>
              ) : (
                game
              )}
            </li>
          ))}
        </ul>
        
        <button 
          onClick={() => setIsOpen(false)}
          className="relative bg-gradient-to-r from-black-400 to-red-500 text-white-100 text-2xl font-bold py-4 px-10 rounded-full transform hover:scale-105 transition-all duration-300 shadow-xl hover:shadow-2xl"
        >
          <span className="relative z-10 text-white">አሁን ይጫወቱ!</span>
          <div className="absolute inset-0 rounded-full bg-red-300 opacity-0 hover:opacity-30 transition-opacity duration-300"></div>
        </button>
        
        {/* <p className="text-yellow-200 text-2xl font-semibold mt-8 animate-pulse">
          የሚወጡበትን ቀን ለማወቅ{' '}
          <a 
            href="t.me/phoenixgames"
            className="underline hover:text-yellow-300 transition-colors duration-300"
          >
            ይጎብኙ
          </a>
        </p> */}
      </div>
      
      <style jsx>{`
        .perspective-1000 {
          perspective: 1000px;
        }
        .glow-yellow {
          box-shadow: 0 0 20px rgba(251, 191, 36, 0.5);
        }
        .text-shadow-glow {
          text-shadow: 0 0 20px rgba(251, 191, 36, 0.5);
        }
        @keyframes float {
          0%, 100% { transform: translateY(0); }
          50% { transform: translateY(-10px); }
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
};

export default NewGamesAnnouncementPopup;