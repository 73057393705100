import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import './Login3D.css';

const API = process.env.REACT_APP_API;
const API_V2 = 'https://phoenix-bingo-2.onrender.com/api';

const BingoBall = ({ number }) => (
  <div className="bingo-ball">
    <span>{number}</span>
  </div>
);

function Bingo3DLogin({ onSubmit, username, setUsername, password, setPassword, apiVersion, setApiVersion, isLoading, showVersionChoice }) {
  const [balls, setBalls] = useState([]);

  useEffect(() => {
    const newBalls = Array.from({ length: 5 }, () => Math.floor(Math.random() * 75) + 1);
    setBalls(newBalls);
  }, []);

  return (
    <div className="bingo-login-container">
      <div className="bingo-balls">
        {balls.map((ball, index) => (
          <BingoBall key={index} number={ball} />
        ))}
      </div>
      <div className="bingo-card">
        <h2>Bingo Login</h2>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {showVersionChoice && (
            <div className="form-group">
              <label>Cartella Version</label>
              <div className="radio-group">
                <label>
                  <input
                    type="radio"
                    value="1"
                    checked={apiVersion === '1'}
                    onChange={(e) => setApiVersion(e.target.value)}
                  /> Cartella 1
                </label>
                <label>
                  <input
                    type="radio"
                    value="2"
                    checked={apiVersion === '2'}
                    onChange={(e) => setApiVersion(e.target.value)}
                  /> Cartella 2
                </label>
              </div>
            </div>
          )}
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'Calling Numbers...' : 'Play Bingo!'}
          </button>
        </form>
      </div>
    </div>
  );
}

async function login(username, password, apiVersion) {
  const baseUrl = apiVersion === '2' ? API_V2 : API;

  if (!baseUrl) {
    throw new Error('API URL is not defined. Please check your environment variables.');
  }

  const apiEndpoint = `${baseUrl}/admin/login`;

  try {

    const response = await fetch(apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (response.ok) {
      if (data.token) {
        const hashedApiVersion = CryptoJS.SHA256(apiVersion).toString();
        localStorage.setItem('token', data.token);
        localStorage.setItem('apiVersion', hashedApiVersion);
        if (data.adminId) {
          localStorage.setItem('adminId', data.adminId);
        }
        return data;
      } else {
        throw new Error('Unauthorized');
      }
    } else {
      throw new Error(data.message || 'Login failed. Please check your credentials.');
    }
  } catch (err) {
    console.error('Login error:', err);
    throw err;
  }
}

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [apiVersion, setApiVersion] = useState('1');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [isPower, setIsPower] = useState(false);
  const [showVersionChoice, setShowVersionChoice] = useState(false);
  const [is3DLogin, setIs3DLogin] = useState(false);

  useEffect(() => {
    const url = window.location.href.toLowerCase();
    setIsPower(url.includes('power'));
    setShowVersionChoice(url.includes('pbingo') || url.includes('phoenix') || url.includes('local'));
    setIs3DLogin(url.includes('local') || url.includes('phoenix'));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login(username, password, apiVersion);
      if (data.adminId) {
        localStorage.setItem('adminId', data.adminId);
      }
      navigate('/dashboard');
    } catch (err) {
      toast.error(err.message);
      console.error('Login error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderApiVersionRadio = () => {
    if (!showVersionChoice) return null;

    return (
      <div className="form-outline mb-4">
        <label className="form-label d-block">Cartella Version</label>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="apiVersion"
            id="apiVersion1"
            value="1"
            checked={apiVersion === '1'}
            onChange={(e) => setApiVersion(e.target.value)}
          />
          <label className="form-check-label" htmlFor="apiVersion1">Cartella 1</label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            type="radio"
            name="apiVersion"
            id="apiVersion2"
            value="2"
            checked={apiVersion === '2'}
            onChange={(e) => setApiVersion(e.target.value)}
          />
          <label className="form-check-label" htmlFor="apiVersion2">Cartella 2</label>
        </div>
      </div>
    );
  };

  if (is3DLogin) {
    return (
      <div className="bingo-3d-container">
        <Bingo3DLogin
          onSubmit={handleSubmit}
          username={username}
          setUsername={setUsername}
          password={password}
          setPassword={setPassword}
          apiVersion={apiVersion}
          setApiVersion={setApiVersion}
          isLoading={isLoading}
          showVersionChoice={showVersionChoice}
        />
        <ToastContainer />
      </div>
    );
  }

  if (isPower) {
    return (
      <div className="py-5 h-100 bg-dark text-white">
        <Form onSubmit={handleSubmit}>
          <section className="vh-100">
            <div className="container py-1 h-75">
              <div className="row d-flex align-items-center justify-content-center h-100">
                <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                  <h1 className='text-center mb-4'>Power Bingo</h1>
                  <div className="form-outline mb-4 d-flex">
                    <label className="form-label mx-2" htmlFor="form1Example13">
                      Username: 
                    </label>
                    <input
                      type="text"
                      id="form1Example13"
                      className="form-control form-control-sm"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>

                  <div className="form-outline mb-4 d-flex">
                    <label className="form-label mx-2" htmlFor="form1Example23">
                      Password: 
                    </label>
                    <input
                      type="password"
                      id="form1Example23"
                      className="form-control form-control-sm"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  {renderApiVersionRadio()}

                  <div className="d-flex justify-content-around align-items-center mb-4">
                    <button type="submit" className="btn btn-warning btn-lg btn-block" disabled={isLoading || !username.includes('power')}>
                      {isLoading ? 'Signing in...' : 'Sign in'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Form>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="container mt-5 py-5 h-100">
      <Form onSubmit={handleSubmit}>
        <section className="vh-100">
          <div className="container py-5 h-100">
            <div className="row d-flex align-items-center justify-content-center h-100">
              <div className="col-md-8 col-lg-7 col-xl-6">
                <img
                  src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
                  className="img-fluid"
                  alt="Phone image"
                />
              </div>
              <div className="col-md-7 col-lg-5 col-xl-5 offset-xl-1">
                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form1Example13">
                    Username
                  </label>
                  <input
                    type="text"
                    id="form1Example13"
                    className="form-control form-control-lg"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>

                <div className="form-outline mb-4">
                  <label className="form-label" htmlFor="form1Example23">
                    Password
                  </label>
                  <input
                    type="password"
                    id="form1Example23"
                    className="form-control form-control-lg"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                {renderApiVersionRadio()}

                <div className="d-flex justify-content-around align-items-center mb-4">
                  <button type="submit" className="btn btn-primary btn-lg btn-block" disabled={isLoading || username.includes('power')}>
                    {isLoading ? 'Signing in...' : 'Sign in'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Form>
      <ToastContainer />
    </div>
  );
}

export default Login;
