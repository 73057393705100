import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Spinner, Alert, Card, Badge } from 'react-bootstrap';
import CryptoJS from 'crypto-js';

function AdminHouseGames() {
  const [dailyProfitLoss, setDailyProfitLoss] = useState(null);
  const [detailedGamesInfo, setDetailedGamesInfo] = useState(null);
  const [profitSummary, setProfitSummary] = useState(null);
  const [gameStats, setGameStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getApiUrl = () => {
    const hashedApiVersion = localStorage.getItem('apiVersion');
    if (hashedApiVersion) {
      const version2Hash = CryptoJS.SHA256('2').toString();
      if (hashedApiVersion === version2Hash) {
        return 'https://phoenix-bingo-2.onrender.com/api';
      }
    }
    return process.env.REACT_APP_API;
  };

  const API = getApiUrl();

  const formatDate = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatDateTime = (dateString) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const token = localStorage.getItem('token');
      const adminId = localStorage.getItem('adminId');

      if (!adminId) {
        setError('Admin ID not found. Please log in again.');
        setLoading(false);
        return;
      }

      const headers = { Authorization: `Bearer ${token}` };

      try {
        // Fetch daily house profit/loss for the last 365 days
        const dailyProfitLossRes = await fetch(`${API}/admin/${adminId}/daily-house-profit-loss`, { headers });
        if (!dailyProfitLossRes.ok) throw new Error('Failed to fetch daily profit/loss data');
        const dailyProfitLossData = await dailyProfitLossRes.json();
        setDailyProfitLoss(dailyProfitLossData);

        // Fetch detailed house games info
        const detailedGamesInfoRes = await fetch(`${API}/admin/${adminId}/detailed-house-games-info`, { headers });
        if (!detailedGamesInfoRes.ok) throw new Error('Failed to fetch detailed games info');
        const detailedGamesInfoData = await detailedGamesInfoRes.json();
        setDetailedGamesInfo(detailedGamesInfoData);

        // Fetch house profit summary
        const profitSummaryRes = await fetch(`${API}/admin/${adminId}/house-profit-summary`, { headers });
        if (!profitSummaryRes.ok) throw new Error('Failed to fetch profit summary');
        const profitSummaryData = await profitSummaryRes.json();
        setProfitSummary(profitSummaryData);

        // Fetch house game stats
        const gameStatsRes = await fetch(`${API}/admin/house-game-stats/${adminId}`, { headers });
        if (!gameStatsRes.ok) throw new Error('Failed to fetch game stats');
        const gameStatsData = await gameStatsRes.json();
        setGameStats(gameStatsData);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('An error occurred while fetching data. Please try again later.');
        setLoading(false);
      }
    };

    fetchData();
  }, [API]);

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status" variant="primary">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-5">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container fluid className="admin-house-games py-4">
      <Row className="mb-4">
        <Col>
          <h1 className="text-primary text-center">Admin Online Games Dashboard</h1>
        </Col>
      </Row>

      {dailyProfitLoss && (
        <Card className="m-2 shadow bg-dark">
          <Card.Header as="h5" className="bg-secondary text-white">Daily House Profit/Loss (Last 365 Days)</Card.Header>
          <Card.Body>
            <Table striped bordered hover responsive variant='dark' className="mb-0">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Total Games</th>
                  <th>Total Profit</th>
                </tr>
              </thead>
              <tbody>
                {dailyProfitLoss.dailyProfits.map((day) => (
                  <tr key={day.date}>
                    <td>{formatDate(day.date)}</td>
                    <td>{day.totalGames}</td>
                    <td className={day.totalProfit >= 0 ? 'text-success' : 'text-danger'}>
                      {day.totalProfit.toFixed(2)} Birr
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          {/* <Card.Footer className="text-muted">
            Date Range: {formatDate(dailyProfitLoss.startDate)} - {formatDate(dailyProfitLoss.endDate)}
          </Card.Footer> */}
        </Card>
      )}

      {detailedGamesInfo && (
        <Card className="m-2 shadow bg-dark">
          <Card.Header as="h5" className="bg-secondary text-white">Detailed House Games Info</Card.Header>
          <Card.Body>
            <Table striped bordered hover responsive variant='dark' className="mb-0">
              <thead className="bg-light">
                <tr>
                  <th>Date and Time</th>
                  <th>Betting Amount</th>
                  <th>Number of Players</th>
                  <th>House Card Count</th>
                  <th>Status</th>
                  <th>Profit</th>
                </tr>
              </thead>
              <tbody>
                {detailedGamesInfo.games.map((game) => (
                  <tr key={game.gameId}>
                    <td>{formatDateTime(game.createdAt)}</td>
                    <td>{game.bettingAmount} Birr</td>
                    <td>{game.numberOfPlayers}</td>
                    <td>{game.houseCardCount}</td>
                    <td>
                      <Badge bg={game.status === 'Completed' ? 'success' : 'warning'}>
                        {game.status}
                      </Badge>
                    </td>
                    <td className={game.profit >= 0 ? 'text-success' : 'text-danger'}>
                      {game.profit.toFixed(2)} Birr
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          {/* <Card.Footer>
            <Row>
              <Col md={4}>
                <strong>Total Profit:</strong> {detailedGamesInfo.totalProfit.toFixed(2)} Birr
              </Col>
              <Col md={4}>
                <strong>Total Games:</strong> {detailedGamesInfo.totalGames}
              </Col>
              <Col md={4}>
                <strong>Current Page:</strong> {detailedGamesInfo.currentPage} of {detailedGamesInfo.totalPages}
              </Col>
            </Row>
          </Card.Footer> */}
        </Card>
      )}

      {/* Uncomment and style these sections if needed
      {profitSummary && (
        <Card className="mb-4 shadow">
          <Card.Header as="h3" className="bg-primary text-white">House Profit Summary</Card.Header>
          <Card.Body>
            {/* Add styled content for profit summary }
          </Card.Body>
        </Card>
      )}

      {gameStats && (
        <Card className="mb-4 shadow">
          <Card.Header as="h3" className="bg-primary text-white">House Game Stats</Card.Header>
          <Card.Body>
            {/* Add styled content for game stats }
          </Card.Body>
        </Card>
      )}
      */}
    </Container>
  );
}

export default AdminHouseGames;