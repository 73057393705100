// Imports
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaChevronDown, FaSync, FaTimes } from 'react-icons/fa'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import BonusModal from './BonusModal';
import NewGamesAnnouncementPopup from './NewGamesAnnouncementPopup';

const MySwal = withReactContent(Swal);

const DashboardPage = () => {
  // State declarations
  const [multiplierBettingAmounts, setMultiplierBettingAmounts] = useState({});
  const [audioLanguage, setAudioLanguage] = useState(localStorage.getItem('audioLanguage') || '');
  const [callSpeed, setCallSpeed] = useState(localStorage.getItem('callSpeed') || '');
  const [winningPattern, setWinningPattern] = useState(localStorage.getItem('winningPattern') || '');
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [defaultBettingAmount, setDefaultBettingAmount] = useState('10');
  const [isLoading, setIsLoading] = useState(false);
  
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  
  // Constants
  const bettingAmountOptions = [5, 10, 15, 20, 25, 30, 35, 40, 50, 60, 70, 75, 100];
  const isPhoenixVersion = window.location.href.toLowerCase().match(/(local|pbingo|phoenix)/);

  // API URL handling
  const getApiUrl = () => {
    const hashedApiVersion = localStorage.getItem('apiVersion');
    if (hashedApiVersion) {
      const version2Hash = CryptoJS.SHA256('2').toString();
      if (hashedApiVersion === version2Hash) {
        return 'https://phoenix-bingo-2.onrender.com/api';
      }
    }
    return process.env.REACT_APP_API;
  };
  const API = getApiUrl();

  // Token check effect
  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
  }, [token, navigate]);

  // Event handlers
  const handleDefaultBettingAmountChange = (value) => {
    setDefaultBettingAmount(value);
  };

  const handleNumberSelect = (number) => {
    if (selectedNumbers.includes(number)) {
      const updatedNumbers = selectedNumbers.filter((n) => n !== number);
      setSelectedNumbers(updatedNumbers);
      setMultiplierBettingAmounts(prevAmounts => {
        const newAmounts = {...prevAmounts};
        delete newAmounts[number];
        return newAmounts;
      });
    } else {
      setSelectedNumbers(prevNumbers => [...prevNumbers, number]);
      setMultiplierBettingAmounts(prevAmounts => ({
        ...prevAmounts,
        [number]: defaultBettingAmount
      }));
    }
    localStorage.setItem('selectedNumbers', JSON.stringify(selectedNumbers));
    localStorage.setItem('multiplierBettingAmounts', JSON.stringify(multiplierBettingAmounts));
  };

  const handleMultiplierBettingAmountChange = (number, value) => {
    setMultiplierBettingAmounts(prevAmounts => ({
      ...prevAmounts,
      [number]: value
    }));
  };

  const handleLocalStorage = () => {
    localStorage.setItem('callSpeed', callSpeed);
    localStorage.setItem('audioLanguage', audioLanguage);
    localStorage.setItem('winningPattern', winningPattern);
  };

  const handleStartGame = async () => {
    setIsLoading(true);
    try {
      const bettingAmountsArray = selectedNumbers.map(number => 
        parseInt(multiplierBettingAmounts[number] || defaultBettingAmount)
      );

      const response = await axios.post(`${API}/game/start`, {
        bettingAmount: bettingAmountsArray,
        cardPaletteNumbers: selectedNumbers,
        callSpeed: parseInt(callSpeed),
        gameType: 'multiplierBingo',
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      navigate('/game-in-progress', { 
        state: { 
          gameId: response.data.gameId, 
          calledNumbers: response.data.calledNumbers, 
          playingCards: response.data.playingCards.map(paletteNumber => ({ paletteNumber })), 
          payoutToWinner: response.data.payoutToWinner, 
          callSpeed, 
          audioLanguage, 
          gameType: 'multiplierBingo',
          multiplier: response.data.multiplier
        } 
      });
    } catch (error) {
      console.error('Error starting the game:', error);
      if (error.response?.status === 400 && error.response?.data?.message === 'Insufficient balance in wallet') {
        toast.error('Insufficient balance in wallet');
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Reload icon component
  const ReloadIcon = ({ onClick }) => (
    <FaSync
      className="text-white hover:text-purple-300 transition-colors duration-200 cursor-pointer"
      size={20}
      onClick={onClick}
    />
  );

  const handleReloadNumbers = () => {
    const storedNumbers = localStorage.getItem('selectedNumbers');
    const storedBettingAmounts = localStorage.getItem('multiplierBettingAmounts');
    
    if (storedNumbers) {
      const parsedNumbers = JSON.parse(storedNumbers);
      setSelectedNumbers(parsedNumbers);
      setMultiplierBettingAmounts(parsedNumbers.reduce((acc, num) => ({
        ...acc,
        [num]: storedBettingAmounts ? JSON.parse(storedBettingAmounts)[num] || defaultBettingAmount : defaultBettingAmount
      }), {}));
    }
  };

  return (
    // Main container with conditional background
    <div className={`min-h-screen ${
      isPhoenixVersion 
        ? 'bg-gradient-to-br from-purple-900 to-red-800' 
        : 'bg-[#242424]'
    }`}>
      <ToastContainer />
      <BonusModal />
      
      {/* Content container */}
      <div className={`${
        isPhoenixVersion 
          ? 'container mx-auto px-6 py-8' 
          : 'container-fluid pl-5 100vh'
      }`}>
        {/* Main grid layout */}
        <div className="grid grid-cols-12 gap-6">
          
          {/* Left Column - Game Settings and Number Grid */}
          <div className={`col-span-8 ${
            isPhoenixVersion 
              ? 'bg-gray-800/50 backdrop-blur-sm rounded-xl shadow-xl' 
              : 'bg-[#141414]'
          } p-6`}>
            
            {/* Settings Grid */}
            <div className="grid grid-cols-2 gap-4 mb-6">
              
              {/* Winning Pattern Select */}
              <div>
                <label className="block text-white mb-2 font-medium">
                  Winning Pattern
                </label>
                <select
                  className={`w-full p-2 ${
                    isPhoenixVersion 
                      ? 'bg-purple-700/50 border-purple-500 rounded-lg focus:ring-2 focus:ring-purple-500' 
                      : 'bg-[#475569] border-none'
                  } text-white`}
                  value={winningPattern}
                  onChange={(e) => setWinningPattern(e.target.value)}
                  onBlur={handleLocalStorage}
                >
                  <option value="">select an option</option>
                  <option value="All">All Common Patterns</option>
                  <option value="Am">Full House</option>
                  <option value="Am">L Shape</option>
                  <option value="Am">Both Diagonal Line</option>
                </select>
              </div>

              <div>
                <label className="block text-white mb-2 font-medium">
                  ውርርድ መጠን (ለእያንዳንዱ ካርቴላ ሊለወጥ የሚችል)
                </label>
                <select
                  className={`w-full p-2 ${
                    isPhoenixVersion 
                      ? 'bg-purple-700/50 border-purple-500 rounded-lg focus:ring-2 focus:ring-purple-500' 
                      : 'bg-[#475569] border-none'
                  } text-white`}
                  value={defaultBettingAmount}
                  onChange={(e) => handleDefaultBettingAmountChange(e.target.value)}
                >
                  {bettingAmountOptions.map(amount => (
                    <option key={amount} value={amount}>{amount}</option>
                  ))}
                </select>
              </div>

              {/* Audio Language Select */}
              <div>
                <label className="block text-white mb-2 font-medium">
                  Audio Language
                </label>
                <select
                  className={`w-full p-2 ${
                    isPhoenixVersion 
                      ? 'bg-purple-700/50 border-purple-500 rounded-lg focus:ring-2 focus:ring-purple-500' 
                      : 'bg-[#475569] border-none'
                  } text-white`}
                  value={audioLanguage}
                  onChange={(e) => setAudioLanguage(e.target.value)}
                  onBlur={handleLocalStorage}
                >
                  <option value="">select an option</option>
                  <option value="male">Amharic Male</option>
                  <option value="female">Amharic Female</option>
                </select>
              </div>

              {/* Call Speed Select */}
              <div>
                <label className="block text-white mb-2 font-medium">
                  Call Speed
                </label>
                <select
                  className={`w-full p-2 ${
                    isPhoenixVersion 
                      ? 'bg-purple-700/50 border-purple-500 rounded-lg focus:ring-2 focus:ring-purple-500' 
                      : 'bg-[#475569] border-none'
                  } text-white`}
                  value={callSpeed}
                  onChange={(e) => setCallSpeed(e.target.value)}
                  onBlur={handleLocalStorage}
                >
                  <option value="">Select Call Speed</option>
                  <option value="5000">5 seconds</option>
                  <option value="6000">6 seconds</option>
                  <option value="7000">7 seconds</option>
                  <option value="10000">10 seconds</option>
                  <option value="15000">15 seconds</option>
                </select>
              </div>
            </div>

            {/* Numbers Grid Section */}
            <div className="mt-6">
              {/* Reload Icon */}
              <div className="flex items-center mb-4">
                <ReloadIcon onClick={handleReloadNumbers} />
              </div>
              
              {/* Numbers Grid Container */}
              <div className={`${
                isPhoenixVersion 
                  ? 'bg-gray-800/30 rounded-xl p-4 shadow-lg' 
                  : ''
              }`}>
                {/* Numbers Grid */}
                <div className="grid grid-cols-10 gap-2">
                  {Array.from({ length: 10 }, (_, i) => (
                    Array.from({ length: 10 }, (_, j) => {
                      const number = i * 10 + j + 1;
                      return (
                        <button
                          key={number}
                          onClick={() => handleNumberSelect(number)}
                          className={`
                            aspect-square text-2xl font-medium rounded-lg transition-all duration-200
                            ${selectedNumbers.includes(number) 
                              ? isPhoenixVersion
                                ? 'bg-purple-500 text-white shadow-lg shadow-purple-500/50' 
                                : 'btn-primary text-white'
                              : isPhoenixVersion
                                ? 'bg-gray-700/50 text-gray-300 hover:bg-purple-500/30'
                                : 'text-[#94A3B8]'
                            }
                          `}
                        >
                          {number}
                        </button>
                      );
                    })
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Right Column - Betting Amounts */}
          <div className="col-span-4">
            <div className={`${
              isPhoenixVersion 
                ? 'bg-gray-800/50 backdrop-blur-sm rounded-xl shadow-xl' 
                : ''
            } p-6`}>
              <h4 className="text-xl font-bold text-white mb-4">
                Betting Amounts
              </h4>
              
              {/* Betting Amounts List */}
              <div className="space-y-3 max-h-96 overflow-y-auto">
                {[...selectedNumbers].reverse().map((number) => (
                  <div 
                    key={number} 
                    className={`${
                      isPhoenixVersion 
                        ? 'bg-purple-700/30 rounded-lg p-3' 
                        : 'bg-gray-700'
                    } flex items-center gap-4`}
                  >
                    <span className="text-white whitespace-nowrap">
                      Cartella {number}:
                    </span>
                    <select
                      value={multiplierBettingAmounts[number] || defaultBettingAmount}
                      onChange={(e) => handleMultiplierBettingAmountChange(number, e.target.value)}
                      className={`flex-1 p-2 rounded ${
                        isPhoenixVersion 
                          ? 'bg-purple-600/50 focus:ring-2 focus:ring-purple-500' 
                          : 'bg-gray-600'
                      } text-white border-none`}
                    >
                      {bettingAmountOptions.map(amount => (
                        <option key={amount} value={amount}>{amount}</option>
                      ))}
                    </select>
                  </div>
                ))}
              </div>

              {/* Start Game Button */}
              <button
                onClick={handleStartGame}
                disabled={!callSpeed || selectedNumbers.length < 2 || isLoading}
                className={`
                  w-full mt-8 py-3 rounded-lg font-bold text-lg transition-all
                  ${isPhoenixVersion 
                    ? 'bg-gradient-to-r from-purple-500 to-indigo-500 hover:from-purple-600 hover:to-indigo-600 text-white shadow-lg shadow-purple-500/30' 
                    : 'btn btn-warning btn-lg'
                  }
                  disabled:opacity-50 disabled:cursor-not-allowed
                `}
              >
                {isLoading ? 'Creating Game...' : 'ጀምር'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <NewGamesAnnouncementPopup />
    </div>
  );
};

export default DashboardPage;