import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from './Components/Login'
import Dashboard from './Components/Dashboard';
import AllCards from './Components/AllCards';
import GamePage from './Components/GamePage';
import AdminGames from './Components/myGames';
import AdminStats from './Components/Stats';
import Navbar from './Components/NavBar';
import Report from './Components/Reports';
import AdminTable from './Components/AllStats';
import Zola from './Components/Zola';
import Keriti from './Components/Keriti';
import MultiplierBingoPage from './Components/MultiplierBingoPage';
import { useEffect, useState } from 'react';
import GamePage2
 from './Components/GamePage2';
import MultiplierBingoStats from './Components/MultiplierBingoStats';
function App() {
  //const [isPowerUrl, setIsPowerUrl] = useState(false);

  // useEffect(() => {
  //   const currentPath = window.location.pathname;
  //   setIsPowerUrl(currentPath.includes('local')); // Check the URL directly
  // }, []);

  // const [visible, setVisible] = useState(true);

  // useEffect(() => {
  //   const protection = {
  //     isHidden: false,

  //     protect() {
  //       this.startMonitoring();
  //       this.addEventListeners();
  //     },

  //     hideContent() {
  //       if (!this.isHidden) {
  //         this.isHidden = true;
  //         setVisible(false);
  //         document.body.style.display = 'none';
  //       }
  //     },

  //     startMonitoring() {
  //       const detectDevTools = () => {
  //         const threshold = 160;
  //         const widthThreshold = window.outerWidth - window.innerWidth > threshold;
  //         const heightThreshold = window.outerHeight - window.innerHeight > threshold;
          
  //         if (widthThreshold || heightThreshold) {
  //           logInspectAttempt('devTools');
  //           this.hideContent();
  //         }
  //       };

  //       const checkDebugger = () => {
  //         const start = performance.now();
  //         debugger;
  //         const end = performance.now();
  //         if (end - start > 100) {
  //           logInspectAttempt('debugger');
  //           this.hideContent();
  //         }
  //       };

  //       // Multiple detection methods
  //       setInterval(detectDevTools, 100);
  //       setInterval(checkDebugger, 100);

  //       // Check element.id for dev tools
  //       const element = new Image();
  //       Object.defineProperty(element, 'id', {
  //         get: () => {
  //           logInspectAttempt('id element');
  //           this.hideContent();
  //         }
  //       });
  //       console.dir(element);
  //     },

  //     addEventListeners() {
  //       // Prevent right-click
  //       document.addEventListener('contextmenu', e => {
  //         e.preventDefault();
  //       });

  //       // Prevent keyboard shortcuts
  //       document.addEventListener('keydown', e => {
  //         const forbiddenKeys = [
  //           { key: 'F12', ctrl: false, shift: false, alt: false },
  //           { key: 'I', ctrl: true, shift: true, alt: false },
  //           { key: 'J', ctrl: true, shift: true, alt: false },
  //           { key: 'C', ctrl: true, shift: true, alt: false },
  //           { key: 'I', meta: true, shift: false, alt: true },
  //           { key: 'J', meta: true, shift: false, alt: true },
  //           { key: 'C', meta: true, shift: false, alt: true },
  //           { key: 'U', ctrl: true, shift: false, alt: false },
  //           { key: 'U', meta: true, shift: false, alt: false }
  //         ];

  //         const isMatch = forbiddenKeys.some(combo => {
  //           return e.key === combo.key &&
  //             (!combo.ctrl || e.ctrlKey) &&
  //             (!combo.shift || e.shiftKey) &&
  //             (!combo.alt || e.altKey) &&
  //             (!combo.meta || e.metaKey);
  //         });

  //         if (isMatch) {
  //           e.preventDefault();
  //           logInspectAttempt('keyboard shortcut');
  //           this.hideContent();
  //         }
  //       });

  //       // Monitor window events
  //       window.addEventListener('resize', () => {
  //         const threshold = 160;
  //         if (window.outerWidth - window.innerWidth > threshold ||
  //             window.outerHeight - window.innerHeight > threshold) {
  //           logInspectAttempt('resize');
  //           this.hideContent();
  //         }
  //       });

  //       // Additional protection against dev tools
  //       const div = document.createElement('div');
  //       let triggered = false;
  //       Object.defineProperty(div, 'id', {
  //         get() {
  //           if (!triggered) {
  //             triggered = true;
  //             protection.hideContent();
  //           }
  //           return undefined;
  //         }
  //       });
  //       setInterval(() => {
  //         console.log(div);
  //       }, 1000);
  //     }
  //   };

  //   protection.protect();

  //   // Cleanup function
  //   return () => {
  //     const highestId = window.setInterval(() => {}, 0);
  //     for (let i = 0; i < highestId; i++) {
  //       window.clearInterval(i);
  //     }
  //   };
  // }, []);

  // async function logInspectAttempt(detectionMethod) {
  //   try {
  //     const adminId = localStorage.getItem('adminId');
  //     if (!adminId) return;
  
  //     await fetch(`${process.env.REACT_APP_API}/inspect/log-inspect-attempt`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         adminId,
  //         detectionMethod,
  //         userAgent: navigator.userAgent
  //       }),
  //     });
  //   } catch (error) {
  //     console.error('Failed to log inspect attempt:', error);
  //   }
  // }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/all-cards" element={<AllCards />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/game-in-progress" element={<GamePage />} />
        <Route path="/reports" element={<Report />} />
        <Route path="/pyt/axs" element={<AdminTable />} />
        <Route path="/multiplier" element={<Dashboard />} />
        {/* {!isPowerUrl && (
          <> */}
            <Route path="/game-in-progress2" element={<GamePage2 />} />
            <Route path="/shashemene" element={<Keriti />} />
            <Route path="/abazh" element={<MultiplierBingoPage />} />
            <Route path="/multiplierStats" element={<MultiplierBingoStats />} />
          {/* </>
        )} */}
        {/* {isPowerUrl && (
          <> */}
            <Route path="/qa/ssl/secured/zola" element={<Zola />} />
          {/* </>
        )} */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;