import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import CryptoJS from 'crypto-js';

function AdminStats() {
  const [stats, setStats] = useState({ statsByPeriod: [] });
  const [multiplierStats, setMultiplierStats] = useState({ dailyProfits: [] });
  const [isLoading, setIsLoading] = useState(true);
  const adminId = localStorage.getItem('adminId');
  const token = localStorage.getItem('token');
  const getApiUrl = () => {
    const hashedApiVersion = localStorage.getItem('apiVersion');
    if (hashedApiVersion) {
      const version2Hash = CryptoJS.SHA256('2').toString();
      if (hashedApiVersion === version2Hash) {
        return 'https://phoenix-bingo-2.onrender.com/api';
      }
    }
    return process.env.REACT_APP_API;
  };

  const API = getApiUrl();
  useEffect(() => {
    const fetchAllStats = async () => {
      try {
        const [regularResponse, multiplierResponse] = await Promise.all([
          fetch(`${API}/admin/stats`, {
            headers: { Authorization: `Bearer ${token}` }}),
          fetch(`${API}/admin/${adminId}/multiplier-profit-summary`)
        ]);

        const regularData = await regularResponse.json();
        const multiplierData = await multiplierResponse.json();

        setStats(regularData);
        setMultiplierStats(multiplierData);
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAllStats();
  }, []);

  // Helper function to format date consistently
  const getFormattedDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
  };

  // Combine profits for entries with the same date
  const getCombinedStats = () => {
    const combined = {};

    // Add regular game profits
    stats.statsByPeriod.forEach(period => {
      const dateStr = getFormattedDate(period.date);
      if (!combined[dateStr]) {
        combined[dateStr] = {
          regularProfit: 0,
          multiplierProfit: 0,
          totalProfit: 0
        };
      }
      combined[dateStr].regularProfit += Number(period.profit || 0);
    });

    // Add multiplier game profits
    multiplierStats.dailyProfits?.forEach(period => {
      const dateStr = getFormattedDate(period.date);
      if (!combined[dateStr]) {
        combined[dateStr] = {
          regularProfit: 0,
          multiplierProfit: 0,
          totalProfit: 0
        };
      }
      combined[dateStr].multiplierProfit += Number(period.totalProfit || 0);
    });

    // Calculate total profits and round all numbers
    Object.keys(combined).forEach(date => {
      combined[date].regularProfit = Math.round(combined[date].regularProfit);
      combined[date].multiplierProfit = Math.round(combined[date].multiplierProfit);
      combined[date].totalProfit = combined[date].regularProfit + combined[date].multiplierProfit;
    });

    return combined;
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const combinedStats = getCombinedStats();

  return (
    <div className='m-4 p-4'>
      <Table bordered hover variant="dark">
        <thead>
          <tr>
            <th style={{backgroundColor: 'black'}}>Date</th>
            <th style={{backgroundColor: 'black'}}>Regular Profit</th>
            <th style={{backgroundColor: 'black'}}>አባዥ Profit</th>
            <th style={{backgroundColor: 'black'}}>Total Profit</th>
          </tr>  
        </thead>
        <tbody>
          {Object.entries(combinedStats)
            .sort((a, b) => new Date(b[0]) - new Date(a[0])) // Sort by date descending
            .map(([date, profits]) => (
              <tr key={date}>
                <td>{date}</td> 
                <td>{profits.regularProfit} Birr</td>
                <td>{profits.multiplierProfit} Birr</td>
                <td>{profits.totalProfit} Birr</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
}

export default AdminStats;
