import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';

const Modal = ({ isOpen, onClose, title, children }) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="text-white sm:max-w-[425px] max-h-[80vh] overflow-y-auto">
      <DialogHeader>
        <DialogTitle>{title}</DialogTitle>
      </DialogHeader>
      <div className="mt-2">{children}</div>
    </DialogContent>
  </Dialog>
);

const AdminMultiplierBingoStats = ({ adminId }) => {
  const [detailedInfo, setDetailedInfo] = useState({ games: [], totalGames: 0, totalProfit: 0, adminUsername: '' });
  const [profitSummary, setProfitSummary] = useState({ dailyProfits: [] });
  const [gameStats, setGameStats] = useState({});
  const [activeTab, setActiveTab] = useState('detailed-info');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGame, setSelectedGame] = useState(null);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    fetchData();
  }, [adminId]);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await Promise.all([
        fetchDetailedInfo(),
        fetchProfitSummary(),
        fetchGameStats()
      ]);
    } catch (error) {
      setError('An error occurred while fetching data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchDetailedInfo = async () => {
    try {
      const response = await fetch(`${API}/admin/${adminId}/detailed-multiplier-games-info`);
      const data = await response.json();
      setDetailedInfo(data);
      console.log(data);
    } catch (error) {
      console.error('Error fetching detailed info:', error);
      setDetailedInfo({ games: [], totalGames: 0, totalProfit: 0, adminUsername: '' });
    }
  };

  const fetchProfitSummary = async () => {
    try {
      const response = await fetch(`${API}/admin/${adminId}/multiplier-profit-summary`);
      const data = await response.json();
      setProfitSummary(data);
    } catch (error) {
      console.error('Error fetching profit summary:', error);
      setProfitSummary({ dailyProfits: [] });
    }
  };

  const renderProfitSummary = () => (
    <div>
      <h3 className="text-xl font-bold mb-4">Profit Summary</h3>
      <p className="mb-4">Total Profit: {profitSummary.dailyProfits.reduce((sum, day) => sum + day.totalProfit, 0)} Birr</p>
      <div className="overflow-x-auto">
        <table className="w-full text-left border-collapse">
          <thead>
            <tr className="bg-dark-100">
              <th className="p-3 border border-gray-300">Date</th>
              <th className="p-3 border border-gray-300">Profit</th>
              <th className="p-3 border border-gray-300">Games Count</th>
            </tr>
          </thead>
          <tbody>
            {profitSummary.dailyProfits.map((day) => (
              <tr key={day.date} className="hover:bg-gray-500">
                <td className="p-3 border border-gray-300">{day.date}</td>
                <td className="p-3 border border-gray-300">{day.totalProfit} Birr</td>
                <td className="p-3 border border-gray-300">{day.gamesCount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  const fetchGameStats = async () => {
    try {
      const response = await fetch(`${API}/admin/multiplier-game-stats/${adminId}`);
      const data = await response.json();
      setGameStats(data);
    } catch (error) {
      console.error('Error fetching game stats:', error);
      setGameStats({});
    }
  };

  const openModal = (game) => {
    setSelectedGame(game);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedGame(null);
  };

  const renderBettingAmounts = (bettingAmounts) => {
    return (
      <ul className="list-disc pl-5">
        {bettingAmounts.map((amount, index) => (
          <li key={index}>{amount} Birr</li>
        ))}
      </ul>
    );
  };

  const renderPlayerCardsAndBets = (playingCards, bettingAmounts) => {
    // Check if arrays exist and have length
    if (!Array.isArray(playingCards) || !Array.isArray(bettingAmounts)) {
      return <div>No card/betting data available</div>;
    }
  
    // Create a map of betting amounts to card numbers
    const groupedCards = playingCards.reduce((acc, card, index) => {
      const amount = bettingAmounts[index];
      if (!acc[amount]) {
        acc[amount] = [];
      }
      acc[amount].push(card);
      return acc;
    }, {});
  
    return Object.entries(groupedCards).map(([amount, cards]) => (
      <div key={amount} className="mb-1">
        {amount} Birr: {cards.join(', ')}
      </div>
    ));
  };

  const tabStyle = "px-4 py-2 font-semibold rounded-t-lg";
  const activeTabStyle = "bg-blue-500 text-white";
  const inactiveTabStyle = "bg-gray-200 text-gray-700 hover:bg-gray-300";

  if (isLoading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="mx-5 my-5 w-full bg-dark text-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-6">አባዥ Bingo Stats</h2>
        <div className="flex mb-6">
          <button
            className={`${tabStyle} ${activeTab === 'detailed-info' ? activeTabStyle : inactiveTabStyle} mr-2`}
            onClick={() => setActiveTab('detailed-info')}
          >
            Detailed Info
          </button>
          <button
            className={`${tabStyle} ${activeTab === 'profit-summary' ? activeTabStyle : inactiveTabStyle} mr-2`}
            onClick={() => setActiveTab('profit-summary')}
          >
            Profit Summary
          </button>
          {/* <button
            className={`${tabStyle} ${activeTab === 'game-stats' ? activeTabStyle : inactiveTabStyle}`}
            onClick={() => setActiveTab('game-stats')}
          >
            Game Stats
          </button> */}
        </div>
        <div className="overflow-x-auto bg-dark">
          {activeTab === 'detailed-info' && (
            <>
              <div className="mb-4">
                <p className="font-bold">Total Games: {detailedInfo.totalGames} - Total Profit: {detailedInfo.totalProfit} Birr</p>
              </div>
              <table class="table table-dark table-striped table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Players</th>
                    <th className="p-3 border border-gray-300">Player Cards & Bets</th>
                    <th scope="col">Total Bet</th>
                    <th scope="col">አባዥ</th>
                    <th scope="col">Winner Card</th>
                    <th scope="col">Payout</th>
                    <th scope="col">Profit</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {detailedInfo.games.map((game) => (
                    <tr key={game.gameId}>
                      <td>{new Date(game.createdAt).toLocaleString()}</td>
                      <td>{game.numberOfPlayers}</td>
                      <td className="p-3 border border-gray-300">
                      {console.log(game)}
                        {Array.isArray(game.playingCards) && Array.isArray(game.bettingAmount) 
                          ? renderPlayerCardsAndBets(game.playingCards, game.bettingAmount)
                          : 'No data available'}
                      </td>
                      <td>{game.bettingAmount.reduce((a, b) => a + b, 0)} Birr</td>
                      <td>{game.multiplier}x</td>
                      <td>{game.winnerCard}</td>
                      <td>{game.payoutToWinner} Birr</td>
                      <td>{(game.bettingAmount.reduce((a, b) => a + b, 0)* 0.8) - game.payoutToWinner} Birr</td>
                      <td>{game.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
          {activeTab === 'profit-summary' && renderProfitSummary()}
          {activeTab === 'game-stats' && (
            <div>
              <h3 className="text-xl font-bold mb-4">Game Statistics</h3>
              <p>Total Games: {gameStats.multiplierGamesStats?.totalGames}</p>
              <p>Total Betting Amount: {gameStats.multiplierGamesStats?.totalBettingAmount} Birr</p>
              <p>Total Payout to Winners: {gameStats.multiplierGamesStats?.totalPayoutToWinners} Birr</p>
              <p>Total Profit: {gameStats.multiplierGamesStats?.totalProfit} Birr</p>
              <p>Average Profit per Game: {gameStats.multiplierGamesStats?.averageProfit} Birr</p>
            </div>
          )}
        </div>
      </div>

      <Modal 
        isOpen={modalOpen} 
        onClose={closeModal} 
        title="Game Details"
      >
        {selectedGame && (
          <table className="table table-striped">
            <tbody>
              <tr>
                <th scope="row">Game ID:</th>
                <td>{selectedGame.gameId}</td>
              </tr>
              <tr>
                <th scope="row">Date:</th>
                <td>{new Date(selectedGame.createdAt).toLocaleString()}</td>
              </tr>
              <tr>
                <th scope="row">Number of Players:</th>
                <td>{selectedGame.numberOfPlayers}</td>
              </tr>
              <tr>
                <th scope="row">Multiplier:</th>
                <td>{selectedGame.multiplier[0]}x</td>
              </tr>
              <tr>
                <th scope="row">Winner Card:</th>
                <td>{selectedGame.winnerCard}</td>
              </tr>
              <tr>
                <th scope="row">Payout to Winner:</th>
                <td>{selectedGame.payoutToWinner} Birr</td>
              </tr>
              <tr>
                <th scope="row">Profit:</th>
                <td>{selectedGame.profit.toFixed(2)} Birr</td>
              </tr>
              <tr>
                <th scope="row">Status:</th>
                <td>{selectedGame.status}</td>
              </tr>
              <tr>
                <th scope="row">Betting Amounts:</th>
                <td>{renderBettingAmounts(selectedGame.bettingAmount)}</td>
              </tr>
            </tbody>
          </table>
        )}
      </Modal>
    </div>
  );
};

export default AdminMultiplierBingoStats;