import React, { useState, useEffect } from 'react';
import { XCircle } from 'lucide-react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';

const Modal = ({ isOpen, onClose, title, children }) => (
  <Dialog open={isOpen} onOpenChange={onClose}>
    <DialogContent className="sm:max-w-[425px] max-h-[80vh] overflow-y-auto">
      <div className="mt-0">{children}</div>
    </DialogContent>
  </Dialog>
);

const MultiplierBingoStats = () => {
  const [detailedInfo, setDetailedInfo] = useState({ games: [], totalGames: 0, totalProfit: 0 });
  const [profitSummary, setProfitSummary] = useState([]);
  const [gameStats, setGameStats] = useState({});
  const [activeTab, setActiveTab] = useState('detailed-info');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAdminData, setSelectedAdminData] = useState(null);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await Promise.all([
        fetchDetailedInfo(),
        fetchProfitSummary(),
        fetchGameStats()
      ]);
    } catch (error) {
      setError('An error occurred while fetching data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const sumBettingAmount = (bettingAmounts) => {
    return Array.isArray(bettingAmounts) 
      ? bettingAmounts.reduce((sum, amount) => sum + amount, 0)
      : 0;
  };

  const fetchDetailedInfo = async () => {
    try {
      const response = await fetch(`${API}/admin/detailed-multiplier-games-info`);
      const data = await response.json();
      setDetailedInfo({
        games: Array.isArray(data.games) ? data.games : [],
        totalGames: data.totalGames || 0,
        totalProfit: data.totalProfit || 0
      });
    } catch (error) {
      console.error('Error fetching detailed info:', error);
      setDetailedInfo({ games: [], totalGames: 0, totalProfit: 0 });
    }
  };

  const fetchProfitSummary = async () => {
    try {
      const response = await fetch(`${API}/admin/multiplier-profits`);
      const data = await response.json();
      
      const sortedData = data.adminsSummary.map(admin => ({
        ...admin,
        months: admin.months.sort((a, b) => new Date(b.month) - new Date(a.month)),
        latestDay: getLatestDay(admin),
        latestWeek: getLatestWeek(admin),
        latestMonth: admin.months[0],
      }));

      setProfitSummary(sortedData.reverse());
    } catch (error) {
      console.error('Error fetching profit summary:', error);
      setProfitSummary([]);
    }
  };

  const renderDailySummary = (selectedAdminData) => {
    if (!selectedAdminData || !selectedAdminData.months) return null;

    const allDays = selectedAdminData.months.flatMap(month => 
      month.weeks.flatMap(week => week.days)
    );

    // Sort the days in descending order (most recent first)
    const sortedDays = allDays.sort((a, b) => new Date(b.date) - new Date(a.date));

    return (
      <div className="space-y-2">
        <h4 className="text-xl font-semibold text-gray-300">{selectedAdminData.adminUsername}'s Daily Summary</h4>
        <div className="max-h-[60vh] overflow-y-auto pr-4 space-y-2">
          {sortedDays.map(day => (
            <div key={day.date} className="bg-gray-800 rounded-lg p-2 shadow-md border border-gray-700">
              <p className="text-sm text-gray-300">{day.date}</p>
              <p className="text-xs text-gray-400">
                Profit: <span className={day.profit >= 0 ? "text-green-400" : "text-red-400"}>{day.profit} Birr</span>
                <span className="ml-2">Games: {day.gamesCount}</span>
              </p>
            </div>
          ))}
        </div>
      </div>
    );
  };


  const getLatestDay = (admin) => {
    const allDays = admin.months.flatMap(month => 
      month.weeks.flatMap(week => week.days)
    );
    return allDays.sort((a, b) => new Date(b.date) - new Date(a.date))[0];
  };

  const getLatestWeek = (admin) => {
    const allWeeks = admin.months.flatMap(month => month.weeks);
    return allWeeks.sort((a, b) => {
      const [yearA, weekA] = a.week.split('-W');
      const [yearB, weekB] = b.week.split('-W');
      if (yearA !== yearB) {
        return parseInt(yearB) - parseInt(yearA);
      }
      return parseInt(weekB) - parseInt(weekA);
    })[0];
  };

  const fetchGameStats = async () => {
    try {
      const response = await fetch(`${API}/admin/all-multiplier-game-stats`);
      const data = await response.json();
      console.log(data);
      setGameStats(data);
    } catch (error) {
      console.error('Error fetching game stats:', error);
      setGameStats({});
    }
  };

  const openModal = (type, adminData) => {
    setSelectedAdminData(adminData);
    setModalOpen({ ...modalOpen, [type]: true });
  };

  const closeModal = (type) => {
    setModalOpen({ ...modalOpen, [type]: false });
    if (Object.values(modalOpen).every(v => !v)) {
      setSelectedAdminData(null);
    }
  };

  const tabStyle = "px-4 py-2 font-semibold rounded-t-lg";
  const activeTabStyle = "bg-blue-500 text-white";
  const inactiveTabStyle = "bg-gray-200 text-gray-700 hover:bg-gray-300";

  if (isLoading) {
    return <div className="text-center p-4">Loading...</div>;
  }

  if (error) {
    return <div className="text-center p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="w-full bg-white rounded-lg shadow-md overflow-hidden">
      <div className="p-6">
        <h2 className="text-2xl font-bold mb-6">አባዥ Bingo Stats</h2>
        <div className="flex mb-6">
          <button
            className={`${tabStyle} ${activeTab === 'detailed-info' ? activeTabStyle : inactiveTabStyle} mr-2`}
            onClick={() => setActiveTab('detailed-info')}
          >
            Detailed Info
          </button>
          <button
            className={`${tabStyle} ${activeTab === 'profit-summary' ? activeTabStyle : inactiveTabStyle} mr-2`}
            onClick={() => setActiveTab('profit-summary')}
          >
            Profit Summary
          </button>
          <button
            className={`${tabStyle} ${activeTab === 'game-stats' ? activeTabStyle : inactiveTabStyle}`}
            onClick={() => setActiveTab('game-stats')}
          >
            Game Stats
          </button>
        </div>
        <div className="overflow-x-auto">
          {activeTab === 'detailed-info' && (
            <>
              <div className="mb-4">
                <p className="font-bold">Total Games: {detailedInfo.totalGames}</p>
                <p className="font-bold">Total Profit: {detailedInfo.totalProfit}</p>
              </div>
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="p-3 border border-gray-300">Date</th>
                    <th className="p-3 border border-gray-300">Players</th>
                    <th className="p-3 border border-gray-300">Total Betting Amount</th>
                    <th className="p-3 border border-gray-300">Payout</th>
                  </tr>
                </thead>
                <tbody>
                  {detailedInfo.games.length > 0 ? (
                    detailedInfo.games.map((game) => (
                      <tr key={game.id} className="hover:bg-gray-50">
                        <td className="p-3 border border-gray-300">{game.createdAt}</td>
                        <td className="p-3 border border-gray-300">{game.numberOfPlayers}</td>
                        <td className="p-3 border border-gray-300">{sumBettingAmount(game.bettingAmount)}</td>
                        <td className="p-3 border border-gray-300">{game.payoutToWinner}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="p-3 text-center">No detailed info available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </>
          )}
           {activeTab === 'profit-summary' && (
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 border border-gray-300">Admin</th>
                  <th className="p-3 border border-gray-300">Total Profit</th>
                  <th className="p-3 border border-gray-300">Daily Profit</th>
                  <th className="p-3 border border-gray-300">Weekly Profit</th>
                  <th className="p-3 border border-gray-300">Monthly Profit</th>
                </tr>
              </thead>
              <tbody>
                {profitSummary.length > 0 ? (
                  profitSummary.map((admin) => (
                    <tr key={admin.id} className="hover:bg-gray-50">
                      <td className="p-3 border border-gray-300">{admin.adminUsername}</td>
                      <td className="p-3 border border-gray-300">{admin.totalProfit}</td>
                      <td className="p-3 border border-gray-300">
                        {admin.latestDay ? (
                            <button
                              onClick={() => openModal('daily', admin)}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm mt-1"
                            >
                              View
                            </button>
                        ) : 'N/A'}
                      </td>
                      <td className="p-3 border border-gray-300">
                        {admin.latestWeek ? (
                          <div>
                            <button
                              onClick={() => openModal('weekly', admin)}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm mt-1"
                            >
                              View
                            </button>
                          </div>
                        ) : 'N/A'}
                      </td>
                      <td className="p-3 border border-gray-300">
                        {admin.latestMonth ? (
                          <div>
                            <button
                              onClick={() => openModal('monthly', admin)}
                              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm mt-1"
                            >
                              View
                            </button>
                          </div>
                        ) : 'N/A'}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="p-3 text-center">No profit summary available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {activeTab === 'game-stats' && (
            <table className="w-full text-left border-collapse">
              <thead>
                <tr className="bg-gray-100">
                  <th className="p-3 border border-gray-300">Username</th>
                  <th className="p-3 border border-gray-300">Total Profit</th>
                  <th className="p-3 border border-gray-300">Average Profit</th>
                  <th className="p-3 border border-gray-300">Total Betting Amount</th>
                  <th className="p-3 border border-gray-300">Total Games</th>
                  <th className="p-3 border border-gray-300">Total Payout to Winners</th>
                </tr>
              </thead>
              <tbody>
                {gameStats.individualStats && gameStats.individualStats.length > 0 ? (
                  gameStats.individualStats.map((stat) => (
                    <tr key={stat._id}>
                      <td className="p-3 border border-gray-300">{stat.username}</td>
                      <td className="p-3 border border-gray-300">{stat.totalProfit}</td>
                      <td className="p-3 border border-gray-300">{stat.averageProfit.toFixed(2)}</td>
                      <td className="p-3 border border-gray-300">{stat.totalBettingAmount}</td>
                      <td className="p-3 border border-gray-300">{stat.totalGames}</td>
                      <td className="p-3 border border-gray-300">{stat.totalPayoutToWinners}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">No game stats available</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>

      <Modal 
        isOpen={modalOpen.daily} 
        onClose={() => closeModal('daily')} 
        title="Daily Profit Breakdown"
      >
        {selectedAdminData && renderDailySummary(selectedAdminData)}
      </Modal>

      <Modal 
        isOpen={modalOpen.weekly} 
        onClose={() => closeModal('weekly')} 
        title="Weekly Profit Breakdown"
      >
        {selectedAdminData && (
          <div className="space-y-2">
            <h4 className="text-xl font-semibold text-gray-300">{selectedAdminData.adminUsername}'s Weekly Summary</h4>
            <div className="max-h-[60vh] overflow-y-auto pr-4 space-y-2">
              {selectedAdminData.months && selectedAdminData.months.flatMap(month => 
                month.weeks.map(week => (
                  <div key={week.week} className="bg-gray-800 rounded-lg p-2 shadow-md border border-gray-700">
                    <h6 className="text-sm font-medium text-gray-300">{week.week}</h6>
                    <p className="text-xs text-gray-400">Weekly Profit: <span className="text-green-400">{week.profit} Birr</span></p>
                  </div>
                ))
              )}
            </div>
          </div>
        )}
      </Modal>

      <Modal 
        isOpen={modalOpen.monthly} 
        onClose={() => closeModal('monthly')} 
        title="Monthly Profit Breakdown"
      >
        {selectedAdminData && (
          <div className="space-y-2">
            <h4 className="text-xl font-semibold text-gray-300">{selectedAdminData.adminUsername}'s Monthly Summary</h4>
            <div className="max-h-[60vh] overflow-y-auto pr-4 space-y-2">
              {selectedAdminData.months && selectedAdminData.months.map(month => (
                <div key={month.month} className="bg-gray-800 rounded-lg p-2 shadow-md border border-gray-700">
                  <h5 className="text-lg font-medium text-gray-200 mb-2">{month.month}</h5>
                  <p className="text-sm text-gray-400">Monthly Profit: <span className="text-green-400">{month.profit} Birr</span></p>
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default MultiplierBingoStats;